<template>
  <v-app id="login" class="primary">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-1 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <img src="./../assets/images/logo.png" class="logo" alt="InsightzClub">
                </div>                
                <v-form>
                  <v-text-field 
                    autocomplete="username"
                    append-icon="person" 
                    name="E-mail" 
                    label="E-mail" 
                    type="text" 
                    v-model="username" 
                    required  
                    v-validate="'required|email'"
                    data-vv-name="email"
                    :error-messages="errors.collect('email')"
                    v-on:click="resetError()"
                  />
                  <v-text-field 
                    autocomplete="current-password"
                    append-icon="lock" 
                    name="password" 
                    label="Password" 
                    type="password" 
                    v-model="password" 
                    required  
                    v-validate="'required|min:8'"
                    data-vv-name="password"
                    :error-messages="errors.collect('password')"
                    v-on:click="resetError()"
                    v-on:keyup.enter="login"
                    @keyup.enter="login"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="login">Login as {{ loginAs }}</v-btn>
                <v-btn color="primary" @click="$router.push({ name: 'VendorRegister' })" v-if="loginAs == 'VENDOR'">Register</v-btn>
              </v-card-actions>
            </v-card>

            <div class="text-caption pt-2 text-end" v-if="loginAs == 'ADMIN'">Something not right? <a href="https://status.insightzclub.com" target="_blank">Check system status</a></div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import DataServiceAdmin from "./../services/dataServiceAdmin.js";
import DataServiceVD from "./../services/dataServiceVD.js";

const dataServiceVD = new DataServiceVD();
const dataServiceAdmin = new DataServiceAdmin();

export default {
  data() {
    return {
      username: null,
      password: null,
      loginAs: "ADMIN",
    }
  },
  mounted() {
    this.loginAs = (window.location.host.includes("vendor.insightzclub.com")) ? "VENDOR" : "ADMIN"
  },
  methods : {
    resetError() {
      this.failed ? this.failed = false : "";
    },
    async login() {
      let status = await this.$validator.validateAll()

      if (status) {
        this.$setLoader()

        let loginDto = {
          email: this.username.toLowerCase(),
          password: this.password
        }

        if (this.loginAs == "ADMIN") {
          dataServiceAdmin.login(loginDto).then(r => {
            this.$store.state.adminUser = r.data.user
            
            window.$cookies.set('izcToken', r.data.token)
            window.$cookies.set('izcRole', "ADMIN")

            this.$router.push({ name: 'Root' })
          })
        } else if (this.loginAs == "VENDOR") {
          dataServiceVD.login(loginDto).then(r => {
            this.$store.state.vendorUser = r.data.user

            window.$cookies.set('izcToken', r.data.token)
            window.$cookies.set('izcRole', "VENDOR")

            this.$router.push({ name: "VendorDashboard" })
          });
        }
      }
    }
  }
};
</script>
<style scoped lang="css">
  .logo {
    height: auto;
    width: 200px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  #login {
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: 0;
  }
</style>
